import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import WideFormatPrinterCostsWrapper from "@components/layouts/wideformatprintercostsWrapper";
import MainBanner from "@components/wideformatprintercosts/mainBanner";

import Benefits from "@components/wideformatprintercosts/benefits";
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function MedicalSoftwareHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <WideFormatPrinterCostsWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <div className="relative w-screen">
                <Benefits />
            </div>
        </WideFormatPrinterCostsWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
